import React, { useEffect, useRef } from "react";
import "@wix/design-system/styles.global.css";
import * as Icons from "@wix/wix-ui-icons-common";
import "./App.css";
import {
  Box,
  Button,
  EmptyState,
  Image,
  Loader,
  Page,
  WixDesignSystemProvider,
} from "@wix/design-system";
import axios from "axios";
import { ReactTagManager } from "react-gtm-ts";

const apps: any = {
  "b91cbbe1-f9ee-40c9-8caf-6c535b5f34f1": {
    iconUrl:
      "https://static.wixstatic.com/media/bec40d_fa90f4a4e8c149a6a425b033a5e343da~mv2.png",
    appName: "Crossy Chicken",
    baseUrl: "https://certifiedcode.wixsite.com/crossy-chicken",
  },
};

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  ReactTagManager.init(tagManagerArgs);
  const [currentAppId, setCurrentAppId] = React.useState(null as any);
  const [instanceId, setInstanceId] = React.useState(null as any);
  const [state, setState] = React.useState(null as any);
  const [instanceData, setInstanceData] = React.useState(null as any);
  const token = new URLSearchParams(window.location.search).get("token");
  const api = useRef(null as any);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const appId = urlParams.get("appId");
    if (appId) {
      setCurrentAppId(appId);
    }
    const instanceId = urlParams.get("instanceId");
    if (instanceId) {
      setInstanceId(instanceId);
    }
    const state = urlParams.get("state");
    if (state) {
      setState(state);
    }
  }, []);

  useEffect(() => {
    if (token && currentAppId) {
      window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=${currentAppId}&redirectUrl=${apps[currentAppId]?.baseUrl}/_functions/@certifiedcode/base-backend/auth`;
    }

    const urlParams = new URLSearchParams(window.location.search);

    const instance = urlParams.get("instance");
    if (instance) {
      if (api.current) {
        api.current = null;
      }

      const newApiInstance = axios.create({
        baseURL: apps[currentAppId]?.baseUrl,
        headers: {
          Authorization: instance || "",
        },
      });

      api.current = newApiInstance;

      setIntercomIdentity();
      getCurrentInstance();
    }
  }, [currentAppId]);

  function setIntercomIdentity() {
    api.current
      .get("/_functions/@certifiedcode/base-backend/intercom")
      .then((response: any) => {
        if (response.data.hash && response.data.email) {
          window.Intercom("boot", {
            email: response.data.email,
            user_hash: response.data.hash,
          });
        }
      })
      .catch(() => {});
  }

  function getCurrentInstance() {
    api.current
      .get("/_functions/@certifiedcode/base-backend/instance")
      .then((response: any) => {
        if (response.data) {
          setInstanceData(response.data);
        }
      })
      .catch(() => {});
  }

  if (token) {
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text="Loading" />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page minWidth={0} height={"100vh"}>
        <Page.Content>
          <Box height={"90vh"} direction="vertical" verticalAlign="middle">
            <EmptyState
              image={
                <Image
                  transparent
                  src={apps[currentAppId]?.iconUrl}
                  width="100px"
                  height="100px"
                />
              }
              title={
                state === "pending-upgrade"
                  ? "This game is not available."
                  : "To setup this game, you need to go to the Editor."
              }
              subtitle={
                state === "pending-upgrade"
                  ? `If you are the site owner, upgrade ${apps[currentAppId]?.appName} Premium plan.`
                  : `Go to Editor > Add Panel (+) > App Widgets > Click "${apps[currentAppId]?.appName}"`
              }
            >
              <Button
                as={"a"}
                target={"_blank"}
                href={`https://www.wix.com/apps/upgrade/${currentAppId}?appInstanceId=${
                  instanceData?.instance?.instanceId || instanceId
                }`}
                skin={
                  instanceData?.instance?.isFree === false
                    ? "premium-light"
                    : "premium"
                }
                prefixIcon={<Icons.PremiumFilled />}
              >
                {instanceData?.instance?.isFree === false
                  ? "Manage Plan"
                  : "Upgrade App"}
              </Button>
            </EmptyState>
          </Box>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
